import { Injectable } from '@angular/core';
import { TrackingService } from '@app/services/tracking.service';
import { compose, Store } from '@ngrx/store';
import { TimesheetClockingBundle } from '@shiftbase-com/models/dist/tracking/timesheet';
import mapValues from 'lodash-es/mapValues';
import orderBy from 'lodash-es/orderBy';
import sortBy from 'lodash-es/sortBy';
import { normalize, NormalizeOutput } from 'normalizr';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, first, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import u from 'updeep';

import { canClockPermission } from '../../../+authenticated/shared/sb-header/sb-header.selectors';
import { maxToday, periodFilter } from '../../../shared/date.helper';
import { defaultTotal, totalAccumulator } from '../../../shared/total.helper';
import { PermissionState } from '../../auth/auth.model';
import { getAuthenticatedUserId } from '../../auth/auth.service';
import { hasPermission } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { departmentFilter, getSelectedDepartmentIds } from '../../selected-departments/selected-departments.service';
import { assignSchemaEntity } from '../../shared/assign';
import { mapAndSortEntities, mapEntity, reformatEntityResponse } from '../../shared/entity.helper';
import { TimesheetSchema } from '../../shared/schemas';
import { DepartmentModel } from '../department/department.model';
import { getDepartmentEntities } from '../department/department.service';
import { getEmployeeEntities } from '../employee/employee.service';
import { LocationModel } from '../location/location.model';
import { getLocationEntities } from '../location/location.service';
import { PermissionOption } from '../permission/permission.model';
import { getRateCardEntities } from '../rate-card/rate-card.service';
import { ShiftModel } from '../shift/shift.model';
import { getShiftEntities } from '../shift/shift.service';
import { TeamModel } from '../team/team.model';
import { getTeamEntities } from '../team/team.service';
import { determineEndTimeForComparison, normalizeTime } from './../../../shared/date.helper';
import { TimesheetAction } from './timesheet.action';
import { TimesheetApi } from './timesheet.api';
import {
  ClockState,
  TimeSheetClockActionType,
  TimeSheetClockAuthorizationLevel,
  TimesheetModel,
  TimesheetsLoadRequest,
  TimesheetState,
} from './timesheet.model';

@Injectable()
export class TimesheetService {
  public constructor(
    private store: Store<AppState>,
    private api: TimesheetApi,
    private trackingService: TrackingService,
  ) {}

  public load(requestData: TimesheetsLoadRequest) {
    return this.api.load(requestData, TimesheetAction.load(requestData)).pipe(
      map((response) => {
        this.store.dispatch(TimesheetAction.loadSuccess(response, requestData));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(TimesheetAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(timesheetData): Observable<any> {
    return this.api.add(timesheetData, TimesheetAction.add(timesheetData)).pipe(
      map((response) => {
        this.store.dispatch(TimesheetAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(TimesheetAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, timesheetData) {
    return this.api.update(id, timesheetData, TimesheetAction.update(timesheetData)).pipe(
      map((response) => {
        this.store.dispatch(TimesheetAction.updateSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(TimesheetAction.updateFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id: string) {
    return this.api.fetch(id, TimesheetAction.fetch(id)).pipe(
      map((response) => {
        this.store.dispatch(TimesheetAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(TimesheetAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public save(timesheetData) {
    if (timesheetData.id) {
      return this.update(timesheetData.id, timesheetData);
    }

    timesheetData = u.omit('id', timesheetData);

    return this.add(timesheetData);
  }

  public batchApprove(ids: string[], status: 'Approved' | 'Declined' | 'Pending') {
    const saveData = ids.map((id) => ({ id, status }));

    return this.api.batchUpdate(saveData, TimesheetAction.batchApprove(saveData)).pipe(
      map((response) => {
        this.store.dispatch(TimesheetAction.batchApproveSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(TimesheetAction.batchApproveFailed(saveData, response));
        return observableThrowError(response);
      }),
    );
  }

  public checkBreak(timesheetData) {
    return this.api.checkBreak(timesheetData);
  }

  public checkRateCard(timesheetData) {
    return this.api.checkRateCard(timesheetData);
  }

  public checkTotal(timesheetData) {
    return this.api.checkTotal(timesheetData);
  }

  public clockOut(timesheetId: string, user_id: string) {
    const timesheetData = { id: timesheetId, user_id };

    return this.api
      .clock(
        timesheetData,
        TimeSheetClockActionType.CLOCK_OUT,
        TimeSheetClockAuthorizationLevel.SUPERVISOR,
        TimesheetAction.clockOut(timesheetId),
      )
      .pipe(
        map((response) => {
          this.trackClocking(response);

          this.store.dispatch(TimesheetAction.updateSuccess(response));

          return observableOf(response);
        }),
        catchError((response) => {
          this.store.dispatch(TimesheetAction.updateFailed(timesheetId, response));
          return observableThrowError(response);
        }),
      );
  }

  public loadClockState() {
    return this.store.select(canClockPermission).pipe(
      first(),
      //complete when not permitted
      takeWhile((permitted) => permitted),
      switchMap(() => this.store.select(getAuthenticatedUserId).pipe(first())),
      switchMap((userId) => this.getClockState(userId)),
    );
  }

  private getClockState(userId) {
    return this.api.clockState(userId).pipe(
      first(),
      tap((response) => this.store.dispatch(TimesheetAction.clockStateSuccess(response))),
      catchError((response) => {
        this.store.dispatch(TimesheetAction.clockStateFailed());
        return observableThrowError(response);
      }),
    );
  }

  public saveClock(
    clockData,
    clockActionType: TimeSheetClockActionType,
    ownClock = true,
    authorizationLevel: TimeSheetClockAuthorizationLevel = TimeSheetClockAuthorizationLevel.APPLICATION,
  ) {
    return this.api.saveClock(clockData, clockActionType, authorizationLevel).pipe(
      tap((response) => {
        this.trackClocking(response, ownClock);

        if (ownClock) {
          this.store.dispatch(TimesheetAction.clockStateSuccess(response));
          return;
        }
        const normalizedResponse = normalize(reformatEntityResponse('Timesheet', response), TimesheetSchema, {
          assignEntity: assignSchemaEntity,
        });
        this.store.dispatch(TimesheetAction.addSuccess(normalizedResponse));
      }),
    );
  }

  public switchShift(
    clockData,
    clockActionType: TimeSheetClockActionType,
    authorizationLevel: TimeSheetClockAuthorizationLevel = TimeSheetClockAuthorizationLevel.APPLICATION,
  ) {
    return this.api.saveClock(clockData, clockActionType, authorizationLevel).pipe(
      tap((response) => {
        this.store.dispatch(TimesheetAction.clockStateUpdateSuccess(response));
        return;
      }),
    );
  }

  private trackClocking(data: ClockState | NormalizeOutput, ownClock = false) {
    const timesheet: TimesheetModel = this.isClockState(data)
      ? data.Timesheet
      : (data.entities?.timesheets?.[data.result] as TimesheetModel);

    if (timesheet) {
      const eventName = timesheet.active_clock ? 'Timesheet User Clocked In' : 'Timesheet User Clocked Out';

      const metaProps: TimesheetClockingBundle = {
        shift_id: timesheet.roster_id,
        employee_id: timesheet.user_id,
        originator: ownClock ? 'avatar' : 'timesheet',
      };

      if (timesheet.active_clock) {
        metaProps.clock_in_time = timesheet.clocked_in;
      } else {
        metaProps.clock_out_time = timesheet.clocked_out;
      }

      this.trackingService.event(eventName, metaProps);
    }
  }

  private isClockState(data): data is ClockState {
    return 'Timesheet' in data;
  }
}

export const sortTimesheets = (timesheets: TimesheetModel[]) => sortBy(timesheets, ['date', 'starttime']);
export const sortTimesheetsDescending = (timesheets: TimesheetModel[]) => orderBy(timesheets, ['date'], ['desc']);
export const mapAndSortTimesheets = mapAndSortEntities(sortTimesheets);
export const mapAndSortTimesheetsDescending = mapAndSortEntities(sortTimesheetsDescending);

export const getTimesheetState = (appState: AppState): TimesheetState => appState.orm.timesheets;

export const getClockState = createSelector(getTimesheetState, (timeSheetState) => timeSheetState.clockState);

export const getTimesheetIds = compose((state) => state.items, getTimesheetState);
export const getTimesheetEntities = createSelector(
  getTimesheetState,
  getTeamEntities,
  getShiftEntities,
  getDepartmentEntities,
  getLocationEntities,
  getEmployeeEntities,
  getRateCardEntities,
  (state, teamEntities, shiftEntities, departmentEntities, locationEntities, employeeEntities, rateCardEntities) =>
    mapValues(state.itemsById, (timesheet) => {
      const team = teamEntities[timesheet.team_id] ? teamEntities[timesheet.team_id] : ({} as TeamModel);
      const shift = shiftEntities[timesheet.shift_id] ? shiftEntities[timesheet.shift_id] : ({} as ShiftModel);
      const departmentId = team.department_id || shift.department_id;
      const department = departmentEntities[departmentId] ? departmentEntities[departmentId] : ({} as DepartmentModel);
      const locationId = department.location_id;
      const location =
        locationId && !!locationEntities[locationId] ? locationEntities[locationId] : ({} as LocationModel);

      return {
        ...timesheet,
        department_id: departmentId,
        Team: team,
        Shift: shift,
        department_name: department.name ? department.name : '',
        location_name: location.name ? location.name : '',
        CreatedBy: timesheet.created_by === '1' ? '' : employeeEntities[timesheet.created_by],
        ModifiedBy: timesheet.modified_by === '1' ? '' : employeeEntities[timesheet.modified_by],
        ReviewedBy:
          timesheet.reviewed_by && timesheet.reviewed_by === '1' ? '' : employeeEntities[timesheet.reviewed_by],
        RateCard: timesheet ? rateCardEntities[timesheet.rate_card_id] : null,
      };
    }),
);
export const getTimesheets = createSelector(getTimesheetIds, getTimesheetEntities, mapAndSortTimesheets);
export const getTimesheetsDesc = createSelector(getTimesheetIds, getTimesheetEntities, mapAndSortTimesheetsDescending);

export const getTimesheet = (id: string) =>
  createSelector(getTimesheetEntities, getEmployeeEntities, (timesheetEntities) => mapEntity(id, timesheetEntities));

export const getTimesheetsForPeriod = (minDate, maxDate) =>
  createSelector(getTimesheets, getSelectedDepartmentIds, (timesheets, departmentIds) =>
    timesheets.filter(periodFilter(minDate, maxToday(maxDate))).filter(departmentFilter(departmentIds)),
  );

export const getTimesheetsForPeriodDesc = (minDate, maxDate) =>
  createSelector(getTimesheetsDesc, getSelectedDepartmentIds, (timesheets, departmentIds) =>
    timesheets.filter(periodFilter(minDate, maxToday(maxDate))).filter(departmentFilter(departmentIds)),
  );

export const sumAllTimesheets = (timesheets: TimesheetModel[]) => {
  if (!timesheets || timesheets.length === 0) {
    return defaultTotal;
  }

  return timesheets.reduce((acc, timesheet: TimesheetModel) => {
    const timesheetTotal = {
      hours: parseFloat(timesheet.total),
      pay: parseFloat(timesheet.salary),
      coc: parseFloat(timesheet.coc),
    };

    return totalAccumulator(acc, timesheetTotal);
  }, defaultTotal);
};

export const sumTimesheets = (timesheets: TimesheetModel[]) => {
  if (!timesheets || timesheets.length === 0) {
    return defaultTotal;
  }

  return timesheets.reduce((acc, timesheet: TimesheetModel) => {
    if (timesheet.status !== 'Approved') {
      return acc;
    }

    const timesheetTotal = {
      hours: parseFloat(timesheet.total),
      pay: parseFloat(timesheet.salary),
      coc: parseFloat(timesheet.coc),
    };

    return totalAccumulator(acc, timesheetTotal);
  }, defaultTotal);
};

export const hasTimesheetPermission =
  (permissions: PermissionOption, permissionState: PermissionState) => (timesheet: TimesheetModel) => {
    const check = {
      permissions,
      userId: timesheet.user_id,
      departments: timesheet.department_id,
    };

    return hasPermission(check, permissionState);
  };

export const getTimesheetsForAuthenticatedUserWithinPeriod = (minDate, maxDate) =>
  createSelector(getAuthenticatedUserId, getTimesheetsDesc, (userId: string, timesheets: TimesheetModel[]) =>
    timesheets
      .filter((timesheet) => timesheet.user_id === userId && timesheet.status === 'Approved')
      .filter(periodFilter(minDate, maxToday(maxDate))),
  );

export const getTimesheetConflicts = (
  userId: string,
  date: string,
  startTime: string,
  endTime: string,
  timesheetId: string = null,
) =>
  createSelector(getTimesheets, (timesheets) =>
    timesheets.filter((timesheetCheck) => {
      if (userId && timesheetCheck.user_id !== userId) {
        return false;
      }

      if (timesheetCheck.date !== date) {
        return false;
      }

      if (timesheetId && timesheetCheck.id === timesheetId) {
        return false;
      }

      if (timesheetCheck.status === 'Declined') {
        return false;
      }

      if (timesheetCheck.active_clock) {
        return false;
      }

      if (!timesheetCheck.starttime || !timesheetCheck.endtime || !startTime || !endTime) {
        return false;
      }

      const timesheetStart = normalizeTime(startTime);
      const timesheetEnd = normalizeTime(determineEndTimeForComparison(startTime, endTime));

      const checkedEnd = normalizeTime(determineEndTimeForComparison(timesheetCheck.starttime, timesheetCheck.endtime));
      const checkedStart = normalizeTime(timesheetCheck.starttime);

      return timesheetStart < checkedEnd && timesheetEnd > checkedStart;
    }),
  );
